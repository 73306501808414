import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Hash } from "react-bootstrap-icons"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Hero() {
  return (
    <Container>
      <Row>
        <Col lg={12} className="align-self-center">
          <Row>
            <Col lg={7} xl={6} className="d-flex flex-column justify-content-end mb-5 mt-4 mt-sm-0">
              <h1 className="visually-hidden">Zelfgemaakt</h1>
              <h2 className="mb-4 h3">Gezond en lekker met een hoekje af</h2>
              <p>
                Wat je zelf doet…
              </p>
              <p>
                …doe je niet alleen beter, ook een stuk gezonder.<br/>
                Al het lekkers van Zelfgemaakt bestaat uit natuurlijke ingrediënten, zonder geraffineerde suiker of schadelijke additieven. Denk aan honing van de lokale imker en fruit en groenten van plaatselijke boeren. Alles verantwoord geteeld en aan een eerlijke prijs.<br/>
                Want geef toe: zonder schuldgevoel smaakt alles toch eens zo lekker?
              </p>
              <Row>
                <Col sm="auto" className="mb-4">
                  <Link to="/over-ons/" className="btn-primary text-light btn text-uppercase">Lees meer over ons</Link>
                </Col>
                <Col sm="auto">
                  <Link to="/producten/" className="btn-outline-secondary btn text-uppercase">Ontdek onze producten</Link>
                </Col>
              </Row>
            </Col>
            <Col className="mb-5">
              <StaticImage src="../../static/img/markt3zelfgemaakt.jpg" alt="Zelfgemaakt kraam producten" placeholder="blurred"/>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
