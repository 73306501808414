import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default function useTestimonials() {
  return (useStaticQuery(graphql`
    query Testimonials {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/testimonials/"}}
        sort: {order: ASC, fields: frontmatter___order}
      ) {
        nodes {
          id
          html
          frontmatter {
            order
            height
            logo {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            logo_alt
            author
            company
          }
        }
      }
    }
  `)).allMarkdownRemark.nodes;
}
