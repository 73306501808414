import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import CollectionsList from "../components/CollectionsList"
import ProductOfTheMonth from "../components/ProductOfTheMonth"
import Testimonials from "../components/Testimonials"
import Hero from "../components/Hero"
import { Seo } from "../components/seo"

export const query = graphql`
  query {
    allShopifyCollection {
      nodes {
        ...CollectionCard
      }
    }
  }
`

export default function IndexPage({ data }) {
  return (
    <Layout headerChildren={<Hero/>}>
      <CollectionsList collections={data?.allShopifyCollection?.nodes} />
      {/*<ProductOfTheMonth />*/}
      <Testimonials />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)
