import * as React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { ChatQuote } from "react-bootstrap-icons"
import useTestimonials from "../hooks/useTestimonials"
import * as classNames from "classnames"

interface Testimonial {
  id: string,
  html: string,
  frontmatter: {
    order: number,
    height: number,
    logo: any,
    logo_alt: string,
    author: string,
    company: string,
  },
}

function sortTestimonialsByHeightAndOrder(testimonials: Testimonial[]): Testimonial[] {
  return testimonials.sort((a, b) => {
    if (a.frontmatter.height === b.frontmatter.height) {
      return a.frontmatter.order - b.frontmatter.order;
    }
    return b.frontmatter.height - a.frontmatter.height;
  })
}

function sum(data: number[]): number {
  return data.reduce((a, b) => a + b, 0);
}

function colHeight(col: Testimonial[]): number {
  const heightArr = col.map(testimonial => testimonial.frontmatter.height)
  return sum(heightArr);
}

function shortestCol(cols: Testimonial[][]): Testimonial[] {
  // Sort and take the first argument as a result
  return cols.sort((a, b) => {
    return colHeight(a) - colHeight(b);
  })[0]
}

function sortToCols(testimonials: Testimonial[], cols: number): Testimonial[][] {
  const result = Array.from({length: cols}, () => [])
  return testimonials.reduce((result, testimonial) => {
    const col = shortestCol(result);
    col.push(testimonial)
    return result;
  }, result);
}

function Testimonial({ html, logo, logoAlt, name, company }) {
  const logoImage = getImage(logo);

  return (
    <Card className="mb-4">
      <Row className="g-0">
        <Col xl={4} xs={12} className="py-4 ps-xl-4">
          <Col xs={6} xl={12} className="mx-auto">
            <GatsbyImage alt={logoAlt} image={logoImage} />
            {/*<StaticImage src="../../static/img/home/recensie-dragonroast.jpg" alt="dragon roast logo" imgClassName="img-thumbnail" />*/}
          </Col>
        </Col>
        <Col xl={8} xs={12}>
          <Card.Body>
            <div className="mb-0" dangerouslySetInnerHTML={{ __html: html }}/>
            {
              name && <p className={classNames("fw-bold", {"mb-0": !!company})}>{name}</p>
            }
            {
              company && <p className="fw-bold small text-primary">{company}</p>
            }
            <ChatQuote className="fs-1 text-primary opacity-50 position-absolute bottom-0 end-0 me-2 mb-2" />
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
}

export default function Testimonials() {
  const testimonials = useTestimonials();

  const sortedTestimonials = sortTestimonialsByHeightAndOrder(testimonials)
  const col3SortedTestimonials = sortToCols(sortedTestimonials, 3)
  const col2SortedTestimonials = sortToCols(sortedTestimonials, 2)

  return (
    <>
      <Container className="mt-8 mb-n6">
        <Row>
          <Col xs={12}>
            <StaticImage src="../../static/img/bg/bannerzelfgemaakt-lekker-van-bij-ons.jpg" alt="Zelfgemaakt producten" />
          </Col>
        </Row>
      </Container>
      <div className="section-bg-1 pt-6">
        <Container className="mt-8">
          <Row>
            <Col>
              <h1 className="text-center display-2 fw-bold d-none d-lg-block">Wat klanten zeggen</h1>
              <h4 className="text-center display-4 fw-bold d-lg-none">Wat klanten zeggen</h4>
            </Col>
          </Row>
          <Row className="d-md-none d-lg-flex">
            <Col xs={12} lg={4}>
              {
                col3SortedTestimonials[0].map(testimonial => (
                  <Testimonial
                    key={testimonial.id}
                    html={testimonial.html}
                    logo={testimonial.frontmatter?.logo}
                    logoAlt={testimonial.frontmatter?.logo_alt}
                    name={testimonial.frontmatter?.author}
                    company={testimonial.frontmatter?.company}
                  />
                ))
              }
            </Col>
            <Col xs={12} lg={4}>
              {
                col3SortedTestimonials[1].map(testimonial => (
                  <Testimonial
                    key={testimonial.id}
                    html={testimonial.html}
                    logo={testimonial.frontmatter?.logo}
                    logoAlt={testimonial.frontmatter?.logo_alt}
                    name={testimonial.frontmatter?.author}
                    company={testimonial.frontmatter?.company}
                  />
                ))
              }
            </Col>
            <Col xs={12} lg={4}>
              {
                col3SortedTestimonials[2].map(testimonial => (
                  <Testimonial
                    key={testimonial.id}
                    html={testimonial.html}
                    logo={testimonial.frontmatter?.logo}
                    logoAlt={testimonial.frontmatter?.logo_alt}
                    name={testimonial.frontmatter?.author}
                    company={testimonial.frontmatter?.company}
                  />
                ))
              }
            </Col>
          </Row>
          <Row className="d-none d-md-flex d-lg-none">
            <Col md={6}>
              {
                col2SortedTestimonials[0].map(testimonial => (
                  <Testimonial
                    key={testimonial.id}
                    html={testimonial.html}
                    logo={testimonial.frontmatter?.logo}
                    logoAlt={testimonial.frontmatter?.logo_alt}
                    name={testimonial.frontmatter?.author}
                    company={testimonial.frontmatter?.company}
                  />
                ))
              }
            </Col>
            <Col md={6}>
              {
                col2SortedTestimonials[1].map(testimonial => (
                  <Testimonial
                    key={testimonial.id}
                    html={testimonial.html}
                    logo={testimonial.frontmatter?.logo}
                    logoAlt={testimonial.frontmatter?.logo_alt}
                    name={testimonial.frontmatter?.author}
                    company={testimonial.frontmatter?.company}
                  />
                ))
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
